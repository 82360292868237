<template>
  <div class="practice-wrap">
    <div class="header" id="pageTop">
      <h1>{{ paperInfo.exam.examName }}</h1>
    </div>
    <a-spin :spinning="spinning">
      <div class="practice-body">
        <div class="content-wrap">
          <div class="question" v-if="currentQue">
            <div class="question-top">
              <h6>{{ questionTypeEnum[currentQue.questionType] }}</h6>
              <span
                ><i>{{ currentIndex + 1 }}</i
                >/{{ dataInfo.questions.length }}</span
              >
            </div>
            <template v-if="[1, 3].includes(currentQue.questionType)">
              <div class="question-tit">{{ currentQue.title }}</div>
              <template v-if="currentQue.titimgs && currentQue.titimgs.length">
                <exam-media :mediaList="currentQue.titimgs" />
              </template>
              <div class="question-opt">
                <a-radio-group
                  v-model:value="currentQue.userAnswer"
                  :disabled="!!currentQue.isOver"
                  @change="grtUserAnswer"
                >
                  <a-radio
                    class="opt-radio"
                    v-model:value="opt.id"
                    v-for="(opt, optIndex) in currentQue.options"
                    :key="opt.id"
                  >
                    <div
                      :class="[
                        'opt-item',
                        {
                          right: currentQue.isOver && opt.isCorrect,
                        },
                        {
                          error: currentQue.isOver && !opt.isCorrect,
                        },
                      ]"
                    >
                      <div class="letter">{{ letterOpts[optIndex] }}</div>
                      <div class="info">
                        <span class="text">{{
                          opt.title || "&nbsp;&nbsp;"
                        }}</span>
                        <exam-media
                          v-if="opt.img"
                          type="option"
                          :mediaList="[opt.img]"
                        />
                      </div>
                    </div>
                  </a-radio>
                </a-radio-group>
              </div>
            </template>
            <template v-else-if="currentQue.questionType == 2">
              <div class="question-tit">{{ currentQue.title }}</div>
              <template v-if="currentQue.titimgs && currentQue.titimgs.length">
                <exam-media :mediaList="currentQue.titimgs" />
              </template>
              <div class="question-opt">
                <a-checkbox-group
                  v-model:value="currentQue.userAnswer"
                  :disabled="!!currentQue.isOver"
                  @change="grtUserAnswer"
                >
                  <a-checkbox
                    class="opt-checkbox"
                    :value="opt.id"
                    v-for="(opt, optIndex) in currentQue.options"
                    :key="opt.id"
                  >
                    <div
                      :class="[
                        'opt-item',
                        {
                          right: currentQue.isOver && opt.isCorrect,
                        },
                        {
                          error: currentQue.isOver && !opt.isCorrect,
                        },
                      ]"
                    >
                      <div class="letter">{{ letterOpts[optIndex] }}</div>
                      <div class="info">
                        <span class="text">{{
                          opt.title || "&nbsp;&nbsp;"
                        }}</span>
                        <exam-media
                          v-if="opt.img"
                          type="option"
                          :mediaList="[opt.img]"
                        />
                      </div>
                    </div>
                  </a-checkbox>
                </a-checkbox-group>
              </div>
            </template>
            <template v-else-if="currentQue.questionType == 4">
              <div class="question-tit">
                <template
                  v-for="(opt, optIndex) in currentQue.options"
                  :key="opt.id"
                >
                  <span>{{ currentQue.titles[optIndex] }}</span>
                  <a-tooltip trigger="focus">
                    <template #title>{{ opt.title }}</template>
                    <input
                      :class="[
                        'opt-input',
                        {
                          right:
                            currentQue.isOver &&
                            opt.title &&
                            currentQue.strAnswer2[optIndex] &&
                            currentQue.strAnswer2[optIndex].indexOf(
                              opt.title
                            ) != -1,
                        },
                        {
                          error:
                            currentQue.isOver &&
                            (!(
                              currentQue.strAnswer2[optIndex] &&
                              currentQue.strAnswer2[optIndex].indexOf(
                                opt.title
                              ) != -1
                            ) ||
                              opt.title == ''),
                        },
                      ]"
                      v-model="opt.title"
                      @input="grtUserAnswer"
                    />
                  </a-tooltip>
                </template>
              </div>
              <template v-if="currentQue.titimgs && currentQue.titimgs.length">
                <exam-media :mediaList="currentQue.titimgs" />
              </template>
            </template>
            <div
              class="question-tips-ctrl"
              v-if="currentQue.analysis && !currentQue.isOver"
            >
              <div class="btn" @click="showTips = !showTips">
                <img src="@/assets/image/practice/tips.png" alt="" />
                {{
                  showTips ? $t("practice.hide_tips") : $t("practice.view_tips")
                }}
              </div>
            </div>
            <div class="question-btn" v-if="currentQue.isDoing">
              <a-button
                type="primary"
                @click="toNext(-1)"
                v-if="currentQue.isOver"
              >
                {{
                  $t(
                    currentIndex + 1 == dataInfo.questions.length
                      ? "XB_Finish"
                      : "LB_Exam_NestQuestion"
                  )
                }}
              </a-button>
              <a-button
                type="primary"
                :loading="submitLoading"
                @click="confirm"
                v-else
              >
                {{
                  $t(
                    currentIndex + 1 == dataInfo.questions.length
                      ? "XB_Finish"
                      : "CM_Confirm"
                  )
                }}
              </a-button>
            </div>
          </div>
          <div class="explain" v-if="showTips">
            <h5>{{ $t("CM_LB_Prompt") }}</h5>
            <p v-if="currentQue.questionType == 4">{{ currentQue.analysis }}</p>
            <p v-else>{{ currentQue.analysis }}</p>
            <analysis-media
              v-if="currentQue.analysisimgs"
              :data="currentQue.analysisimgs"
            />
          </div>
          <div class="explain" v-if="currentQue && currentQue.isOver">
            <h5>{{ $t("practice.answer_analysis") }}</h5>
            <p>
              <template v-if="currentQue.questionType == 4">
                {{ $t("Lab_Lesson_RightKey") }}：<span
                  class="right-opt"
                  v-for="(oItem, oIndex) in currentQue.strAnswer2"
                  :key="oIndex"
                >
                  {{ oIndex + 1 }}、<span>{{
                    oItem.replaceAll("$", " / ")
                  }}</span>
                  &nbsp;
                </span>
              </template>
              <template v-else>
                {{ $t("Lab_Lesson_RightKey") }}：<template
                  v-for="(opt, optIndex) in currentQue.options"
                >
                  <span
                    class="right-opt"
                    :key="opt.id"
                    v-if="currentQue.strAnswer2.includes(opt.id)"
                    >{{ letterOpts[optIndex] }}</span
                  >
                </template>
              </template>
            </p>
            <p>{{ currentQue.analysis }}</p>
            <analysis-media
              v-if="currentQue.analysisimgs"
              :data="currentQue.analysisimgs"
            />
          </div>
        </div>

        <div class="sheet-wrap" v-if="dataInfo.questions.length">
          <div class="sheet">
            <div class="sheet-head">
              <h5>{{ $t("LB_AnswerSheet") }}</h5>
              <!-- 答题卡 -->
              <div class="block ed"><span></span>{{ $t("CM_Answer") }}</div>
              <!-- 已答 -->
              <div class="block no"><span></span>{{ $t("CM_NoAnswer") }}</div>
              <!-- 未答 -->
            </div>
            <div class="sheet-number">{{ $t("practice.question_number") }}</div>
            <div class="sheet-list">
              <div
                class="item"
                v-for="(item, queIndex) in dataInfo.questions"
                :key="item.questionId"
                :class="{
                  checked: item.isOver,
                  current: queIndex == currentIndex,
                }"
                @click="toNext(queIndex)"
              >
                {{ queIndex + 1 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, createVNode } from "vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";

import { getTaskDetail } from "@/api/project";
import { failQuestions, practiceSubmit } from "@/api/practice";

import ls from "@/utils/local-storage";
import { STORAGE_TOKEN_KEY } from "@/store/mutation-type";
import { letterOpts, questionTypeEnum } from "@/utils/business";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import examMedia from "@/views/exam/exam-media.vue";
import analysisMedia from "@/views/exam/analysis-media.vue";
export default {
  components: {
    examMedia,
    analysisMedia,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      spinning: true,
      taskId: Number(route.query.taskId || 0),
      detailId: Number(route.query.detailId || 0),
      paperInfo: { exam: {} },
      dataInfo: {
        questions: [],
      },
      currentIndex: 0,
      currentQue: null,
      showTips: false,
      submitLoading: false,
    });
    let isComplete = false;

    getTaskDetail({
      id: state.taskId,
      did: state.detailId,
    }).then((res) => {
      state.paperInfo = res.data;
    });

    const lsToken = ls.get(STORAGE_TOKEN_KEY);
    const endSubmit = () => {
      if (state.dataInfo.questions.length && !isComplete) {
        fetch(
          process.env.VUE_APP_API_BASE_URL +
            `study/practice/${state.dataInfo.submitId}/${state.currentQue.questionId}/end`,
          {
            method: "get",
            headers: {
              Authorization: "token " + lsToken,
            },
            keepalive: true,
          }
        );
      }
    };

    onBeforeRouteLeave((to, from, next) => {
      if (!isComplete) {
        Modal.confirm({
          title: proxy.$t("practice.exit_confirmation"),
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            endSubmit();
            next();
          },
        });
      } else {
        next();
      }
    });

    const shuffle = (array) => {
      var m = array.length,
        t,
        i;
      while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }
      return array;
    };

    const initCorrectAnswers = (item) => {
      item.options.map((o, oIndex) => {
        if (item.questionType == 4 && item.strAnswer2[oIndex]) {
          if (item.strAnswer2[oIndex].split("$").includes(o.title)) {
            o.isCorrect = true;
          }
        } else {
          if (item.strAnswer2.includes(o.id)) {
            o.isCorrect = true;
          }
        }
      });
    };

    const getDetail = () => {
      state.spinning = true;
      failQuestions({
        taskid: state.taskId,
        detailid: state.detailId,
      }).then((res) => {
        state.spinning = false;
        if (res.ret === 0) {
          state.dataInfo = res.data;
          state.dataInfo.questions = state.dataInfo.questions || [];
          if (state.dataInfo.questions.length > 0) {
            let lastQuestionId = res.data.lastQuestionId || 0;
            let lastQuestionIndex = 0;
            state.dataInfo.questions.map((item, index) => {
              item.strAnswer2 = JSON.parse(item.strAnswer);
              item.answers = [];
              item.options = item.options || [];
              if (item.questionType == 4) {
                item.titles = item.title.split("＿");
                if (!item.options.length) {
                  for (var i = 0; i < item.titles.length - 1; i++) {
                    item.options.push({ id: i, title: "", isRight: false });
                  }
                }
              } else {
                if (item.optionRandom == 1) {
                  item.options = shuffle(item.options);
                }
              }
              initCorrectAnswers(item);
              if (item.questionId == lastQuestionId) lastQuestionIndex = index;
            });
            state.currentIndex = lastQuestionIndex;
            state.currentQue = state.dataInfo.questions[state.currentIndex];
          }
        } else {
          isComplete = true;
        }
      });
    };
    getDetail();

    const grtUserAnswer = () => {
      setTimeout(() => {
        let currentQue = state.currentQue;
        if ([1, 3].includes(currentQue.questionType)) {
          if (currentQue.userAnswer || currentQue.userAnswer === 0) {
            currentQue.isDoing = true;
            currentQue.options.map((item) => {
              item.isRight = false;
              if (item.id == currentQue.userAnswer) {
                item.isRight = true;
              }
            });
            currentQue.answers = [currentQue.userAnswer];
          } else {
            currentQue.isDoing = false;
          }
        } else if (currentQue.questionType == 2) {
          if (currentQue.userAnswer && currentQue.userAnswer.length >= 2) {
            currentQue.isDoing = true;
            currentQue.options.map((item) => {
              item.isRight = false;
              if (currentQue.userAnswer.includes(item.id)) {
                item.isRight = true;
              }
            });
            currentQue.answers = JSON.parse(
              JSON.stringify(currentQue.userAnswer)
            );
          } else {
            currentQue.isDoing = false;
          }
        } else if (currentQue.questionType == 4) {
          currentQue.isDoing = false;
          currentQue.userAnswer = [];
          currentQue.options.map((arr) => {
            if (arr.title.trim() === "") {
              arr.isRight = false;
            } else {
              currentQue.isDoing = true;
              currentQue.userAnswer.push(arr.id);
              arr.isRight = true;
            }
          });
          currentQue.answers = currentQue.userAnswer;
        }
      }, 100);
    };

    const toResult = () => {
      isComplete = true;
      router.replace({
        path: "/exam/practice/result",
        query: {
          submitId: state.dataInfo.submitId,
          taskId: state.taskId,
          detailId: state.detailId,
        },
      });
    };

    const toNext = (index) => {
      state.showTips = false;
      if (
        state.currentIndex + 1 == state.dataInfo.questions.length &&
        index == -1
      ) {
        let surplus = 0;
        state.dataInfo.questions.forEach((item) => {
          if (!item.isOver) surplus++;
        });
        if (surplus) {
          proxy.$message.warning(
            proxy.$t("practice.question_unanswered", [surplus])
          );
        } else {
          toResult();
        }
      } else {
        if (index != -1) {
          state.currentIndex = index;
        } else {
          state.currentIndex++;
        }
        state.currentQue = state.dataInfo.questions[state.currentIndex];
        setTimeout(() => {
          document.getElementById("pageTop").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 0);
      }
    };

    const confirm = () => {
      state.showTips = false;
      state.currentQue.isOver = true;
      state.submitLoading = true;
      state.currentQue.submitId = state.dataInfo.submitId;
      practiceSubmit(state.currentQue).then((res) => {
        state.submitLoading = false;
        if (res.ret == 0) {
          state.currentQue.isCorrect = res.data;
          if (res.data) {
            toNext(-1);
          }
        }
      });
    };

    return {
      questionTypeEnum,
      letterOpts,
      ...toRefs(state),
      grtUserAnswer,
      toNext,
      confirm,
    };
  },
};
</script>

<style lang="less" scoped>
.practice-wrap {
  .mixinWrap();
}
.header {
  padding: 24px 0;
  h1 {
    margin-bottom: 0;
    line-height: 26px;
    font-size: 18px;
  }
}
.practice-body {
  .mixinFlex(space-between);
  padding-bottom: 24px;
}
.content-wrap {
  width: 888px;
  min-height: 200px;
  position: relative;
  .question {
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 10px rgba(148, 148, 148, 0.15);
    padding: 16px;
    &-top {
      .mixinFlex(space-between; center);
      line-height: 26px;
      margin-bottom: 18px;
      h6 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
        i {
          font-size: 18px;
          font-weight: 600;
          color: @color-theme;
        }
      }
    }
    &-tit {
      font-size: 16px;
      line-height: 26px;
      color: #333;
      margin-bottom: 18px;
    }
    .opt-input {
      margin: 0 5px;
      border: none;
      border-bottom: 1px solid #202020;
      padding: 1px 4px;
      text-align: center;
      &.text {
        padding: 1px 16px;
      }
      &.right {
        border-color: #2ec45a;
      }
      &.error {
        border-color: #e82f2e;
      }
    }
    &-opt {
      .opt-checkbox,
      .opt-radio {
        .mixinFlex(flex-start; center);
        white-space: normal !important;
        ::v-deep(.ant-checkbox),
        ::v-deep(.ant-radio) {
          display: none;
          width: 0px;
          height: 0px;
          overflow: hidden;
        }
        .opt-item {
          .mixinFlex(flex-start; flex-start);
          font-size: 16px;
          margin-bottom: 16px;
          .letter {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            .mixinFlex(center; center);
            margin-right: 20px;
            border: 1px solid #dddddd;
          }
          .info {
            width: calc(100% - 48px);
          }
          .text {
            color: #202020;
            line-height: 28px;
            max-width: calc(100% - 48px);
          }
        }
        &.ant-checkbox-wrapper-checked,
        &.ant-radio-wrapper-checked {
          .letter {
            border-color: #266fff;
            color: #fff;
            background-color: #266fff;
          }
          .text {
            color: #266fff;
          }
          .error {
            .letter {
              border: none;
              background-color: #f65464;
              color: #fff;
            }
          }
        }
      }
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
    &-tips-ctrl {
      .mixinFlex(flex-end);
      .btn {
        .mixinFlex(flex-start; center);
        color: #377dff;
        cursor: pointer;
        user-select: none;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }
    .error-number {
      font-size: 18px;
      line-height: 26px;
      color: #e82f2e;
      text-align: center;
      margin: 32px 0 8px;
    }
    .error-remove {
      .mixinFlex(flex-end);
      .btn {
        .mixinFlex(flex-start; center);
        color: #377dff;
        cursor: pointer;
        user-select: none;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }
    &-btn {
      padding-top: 24px;
      text-align: center;
    }
  }
  .explain {
    h5 {
      margin: 24px 0 16px;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      border-left: 6px solid @color-theme;
      padding-left: 8px;
    }
    p {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 26px;
      word-break: break-all;
      .right-opt {
        padding-right: 8px;
        color: #22cf33;
      }
    }
  }
  .answer-sheet {
    width: 80px;
    position: absolute;
    right: 16px;
    .btn {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 2px 10px rgba(148, 148, 148, 0.15);
      .mixinFlex(center; center);
      flex-direction: column;
      cursor: pointer;
      position: fixed;
      bottom: 150px;
      .img {
        .mixinImgWrap(28px; 28px);
      }
      span {
        font-size: 12px;
        line-height: 17px;
        color: #377dff;
        margin-top: 2px;
      }
    }
  }
}
.sheet-wrap {
  width: 296px;
}
.sheet {
  padding: 16px 16px 0 16px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(148, 148, 148, 0.15);
  &-head {
    .mixinFlex(flex-start; center);
    margin-bottom: 20px;
    h5 {
      font-size: 16px;
      color: #333;
      line-height: 24px;
      margin-bottom: 0;
      font-weight: 600;
      margin-right: 24px;
    }
    .block {
      .mixinFlex(flex-start; center);
      margin-right: 24px;
      span {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        border-radius: 2px;
      }
      &.ed {
        span {
          border: 1px solid #447dff;
          background-color: #447dff;
        }
      }
      &.no {
        span {
          border: 1px solid #999999;
        }
      }
    }
  }
  &-number {
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    margin-bottom: 16px;
  }
  &-list {
    max-height: 500px;
    overflow-y: auto;
    .item {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 2px;
      border: 1px solid #999999;
      cursor: pointer;
      margin-right: 16px;
      margin-bottom: 16px;
      color: #999999;
      font-size: 18px;
      .mixinFlex(center; center);
      display: inline-flex;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &.checked {
        border-color: #447dff;
        background-color: #447dff;
        color: #fff;
      }
      &.current {
        border-color: rgba(68, 125, 255, 0.2);
        color: #447dff;
        background-color: rgba(68, 125, 255, 0.2);
        pointer-events: none;
      }
    }
  }
}
</style>
